.product-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;

  padding: 5px 10px;

  transition: box-shadow $trans-default;
  border-radius: 5px;

  background-color: #fefefe;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;

  @include hover-focus {
    box-shadow: rgba(0, 0, 0, 0.25) 0 5px 15px;

    &:active {
      box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    }
  }

  &.is-hidden {
    display: none;
  }

  &.is-filtered {
    display: none;
  }


  @include vp-1023 {
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.product-card__wrapper {
  width: calc(100% - 150px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6%;

  @include vp-1279 {
    gap: 3%;
  }

  @include vp-1023 {
    flex-direction: column;
    width: 100%;
  }
}

.product-card__top {
  width: calc(100% - 340px);
  // padding: 0 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6%;

  @include vp-1279 {
    width: calc(100% - 200px);
    gap: 2px;
  }

  @include vp-1023 {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 5px 0;
  }

}

.product-card__title {
  width: 250px;

  vertical-align: middle;
  text-transform: uppercase;


  font-size: 18px;
  font-weight: 600;
  line-height: 110%;
  color: #000000;

  @include vp-1279 {
    font-size: 16px;
    width: 200px;
  }

  @include vp-1023 {
    font-size: 13px;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(#000000, 0.2);
  }

  @include vp-767 {
    font-size: 14px;
  }
}

.product-card__group {
  max-width: 180px;
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  // color: $color-accent-main;
  color: #9D9D9D;
  line-height: 120%;
  flex-grow: 1;
  width: 80%;

  @include vp-1279 {
    width: 70%;
  }

  @include vp-1023 {
    width: 100%;
  }
}

.product-card__unit {
  width: max-content;
  max-width: 200px;
  display: flex;
  gap: 6px;
  justify-content: flex-start;

  span {
    display: inline;
  }
}

.product-card__value {
  width: max-content;
  font-weight: 400;
  font-size: 14px;
  color: #9D9D9D;
  line-height: normal;

  @include vp-1279 {
    font-size: 12px;
  }

  @include vp-1023 {
    padding-top: 12px;
    // width: 100%;
    // font-size: 10px;
  }
}

.product-card__btn {
  padding-left: 0;
  width: fit-content;
  gap: 12px;
  letter-spacing: 1px;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 12px;

  transition: color $trans-default, opacity $trans-default;

  svg {
    transition: transform $trans-default;
    width: 6px;
    height: auto;

    @include vp-767 {
      width: 4px;
      height: auto;
    }
  }

  @include vp-1023 {
    font-size: 10px;
  }

  @include hover-focus {
    color: $color-accent-main;

    svg {
      transform: translate(8px);
    }

    &:active {
      svg {
        transform: translate(-10px);
      }
    }
  }

  &:active {
    svg {
      transform: translate(-10px);
    }
  }

  &:disabled {
    opacity: 0.2;
  }
}
