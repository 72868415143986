.products-catalog {
  width: 100%;
  display: flex;
  padding-bottom: 80px;

  @include vp-767 {
    padding-bottom: 40px;
  }
}

.products-catalog__wrapper {
  display: grid;
  grid-template-columns: 32% 1fr;
  width: 100%;
  gap: 39px;
  padding-bottom: 40px;

  @include vp-1023 {
    grid-template-columns: 40% 1fr;
    gap: 3%;
    padding-bottom: 0;
  }

  @include vp-767 {
    grid-template-columns: 1fr;
    gap: 1%;
  }
}

.products-catalog__inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @include vp-767 {
    padding-bottom: 20px;
  }

}

.products-catalog__catalog {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 45px;

  @include vp-1023 {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 34px;
  }

  @include vp-767 {
    grid-template-columns: 1fr;
    gap: 24px;
    margin-bottom: 30px;
  }
}

.products-catalog__button {
  justify-self: center;
  align-self: center;

  &.is-hidden {
    display: none;
  }
}
