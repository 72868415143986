.swiper-button {
  background-color: $color-accent-main;
  color: $color-default-white;
  border-radius: 3px;

  width: 36px;
  height: 36px;
  top: auto;
  bottom: 20px;

  &::after {
    font-size: 18px;
  }

  &--next {
    left: calc(50% + 20px);
  }

  &--prev {
    left: calc(50% - 56px);
  }

  @include vp-767 {
    width: 24px;
    height: 24px;

    &::after {
      font-size: 14px;
    }

    &--next {
      left: calc(50% + 15px);
    }

    &--prev {
      left: calc(50% - 27px);
    }
  }
}
