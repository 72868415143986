.hero {
  display: flex;
  overflow: hidden;
  aspect-ratio: 1811 / 759;

  @include vp-1327 {
    aspect-ratio: 1811 / 850;
  }

  @include vp-1279 {
    aspect-ratio: 1811 /900;
  }

  @include vp-1023 {
    aspect-ratio: 768 / 436;
  }

  @include vp-767 {
    aspect-ratio: 320 / 220;
  }

  @include vp-569 {
    aspect-ratio: 320 / 280;
  }
}

.hero__swiper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.hero__wrapper {
  width: 100%;
  height: 100%;
  align-items: stretch;
}

// .hero__parallax {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 130%;
//   height: 100%;
//   z-index: -10;
// }

.hero__pagination.swiper-pagination-bullets {
  bottom: 13%;
  width: fit-content;
  left: auto;
  right: 8%;

  @include vp-767 {
    bottom: 5%;
    right: 20px;
  }
}

.swiper-pagination span {
  width: 15px;
  height: 15px;
  border-width: 2px;
  border-style: solid;
  border-color: $color-default-white;
  background-color: transparent;
  opacity: 1;
  transition: border-color $trans-default, background-color $trans-default, opacity $trans-default;

  &.swiper-pagination-bullet-active {
    background-color: $color-default-white;

    @include vp-767 {
      background-color: $color-default-black;
    }

    @include hover-focus {
      border-color: $color-accent-main;
      background-color: $color-accent-main;
      outline: none;
    }

    &:active {
      opacity: 0.5;
    }
  }

  @include vp-1023 {
    width: 10px;
    height: 10px;
    border-width: 1px;
  }

  @include vp-767 {
    border-color: $color-default-black;
    width: 8px;
    height: 8px;
  }

  @include hover-focus {
    border-color: $color-accent-main;
    outline: none;
  }

  &:active {
    opacity: 0.5;
  }
}
