.form-modal {
  padding: 20px;
}

.form-modal__text {
  font-size: 14px;
  line-height: 150%;

  @include vp-767 {
    font-size: 12px;

  }
}

.form-modal__btn {
  align-self: center;

  &::after {
    z-index: 0;
  }
}

.form-modal__toggle {
  input {
    @include hover-focus {
      & + .toggle__icon {
        border-color: $color-accent-main;
      }
    }
  }
  .toggle__icon {
    border-color: rgba($color-default-black, 0.3);
    border-radius: 4px;
  }
}
