.footer {
  padding: 40px 0;

  background-color: $color-bg-dark;

  @include vp-1023 {
    padding: 24px 0;
  }
  @include vp-767 {
    padding: 20px 0 10px;
  }
}

.footer__wrapper {
  display: grid;
  grid-template-columns: 102px 1fr 20%;
  gap: 10%;

  @include vp-1023 {
    gap: 2%;
    grid-template-columns: 102px 1fr 20%;
  }
  @include vp-767 {
    grid-template-columns: 135px 1fr;
    grid-template-areas:
      "logo list"
      "copy list";
  }
}

.footer__list {
  list-style: none;
  margin: 0;
  padding: 0;

  align-self: center;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  @include vp-1023 {}
  @include vp-767 {
    flex-direction: column;
    gap: 5px;
    grid-area: list;
    align-items: flex-end;
  }
}

.footer__link {
  color: $color-default-white;
  font-size: 18px;
  font-weight: 600;

  transition: color $trans-default;

  @include vp-1023 {
    font-size: 16px;
  }
  @include vp-767 {
    font-size: 14px;
    line-height: 150%;
  }

  @include hover-focus {
    color: $color-accent-main;
  }

  &:active {
    color: $color-accent-main;
  }
}

.footer__copyright {
  color: #7e7e7e;
  line-height: 160%;
  text-align: right;
  cursor: pointer;

  align-self: center;

  @include vp-1023 {
    font-size: 14px;
  }
  @include vp-767 {
    text-align: left;
    font-size: 10px;
    grid-area: copy;
  }
}

.footer__logo {
  @include vp-767 {
    grid-area: logo;
  }
}
