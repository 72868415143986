.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background-color: rgba($color-default-black, 0.4);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;

  transition: opacity $trans-modal, transform $trans-modal, visibility $trans-modal;

  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 60px 40px;

    @include vp-767 {
      padding: 60px 16px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;

    width: 600px;
    padding: 40px;

    background-color: $color-default-white;

    @include vp-767 {
      width: 100%;
      padding: 20px;
    }
  }

  &__close-btn {
    position: absolute;
    top: 22px;
    right: 22px;
    z-index: 1;

    width: 40px;
    height: 40px;
    padding: 0;

    background: $color-transparent;
    border: none;
    cursor: pointer;

    transition: color $trans-default;

    @include hover-focus {
      color: $color-accent-main
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include vp-767 {
        width: 100%;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    transition: none;
  }

  &.is-active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;

    pointer-events: auto;
  }

  &--link {
    .modal__content {
      overflow: hidden;
      border-radius: 10px;
      display: flex;
    }

    .about-link-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .about-link-modal__text {
      align-self: center;
      margin: 0;
      padding: 30px 0;
      font-weight: 500;
      font-size: 18px;

      @include vp-767 {
        font-size: 14px;
      }
    }

    .about-link-modal__bottom {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5%;

      .btn {
        border: none;
        background-color: transparent;
        transition: color $trans-default;

        font-size: 18px;

        @include hover-focus {
          color: $color-accent-main;
        }

        &:active {
          color: $color-accent-main;
        }
      }
    }
  }
}
