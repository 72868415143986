.main-nav {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.main-nav__link {
  padding: 40px 10px 60px;

  color: $color-default-black;

  font-weight: 600;
  font-size: 18px;
  line-height: 150%;

  cursor: pointer;
  transition: color $trans-default, opacity $trans-default;

  @include vp-1279 {
    font-size: 14px;
  }

  @include vp-767 {
    padding: 20px;
    font-size: 12px;
  }

  svg {
    transform: rotate(0);
    transition: transform $trans-default;

    @include vp-1279 {
      width: 10px;
      height: auto;
    }
  }

  // &.is-active {

  //   &::after {
  //     content: "";
  //     width: 100%;
  //     height: 2px;
  //     background-color: $color-default-black;
  //     position: absolute;
  //     left: 0;
  //     bottom: 0;
  //   }
  // }

  @include hover-focus {
    color: $color-accent-main;
    outline: none;

    svg {
      transform: rotate(180deg);
    }
  }

  &:active {
    opacity: 0.5;
  }
}

.main-nav__list {
  margin: 0;
  padding: 0;

  list-style: none;

  display: flex;
  flex-grow: 1;
  justify-self: center;
  gap: 3%;

  @include vp-1023 {
    gap: 1%;
  }

  @include vp-767 {
    flex-direction: column;
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: $color-light-gray;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}

.main-nav__item {
  @include vp-767 {
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: $color-light-gray;

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}


// ПОДМЕНЮ

.main-nav__submenu {
  width: 100%;
  padding-top: 60px;

  display: flex;

  position: absolute;
  top: 100%;
  left: 0;
  background-color: #FCFAF8;
  border: 1px solid #EAEAEA;
  box-shadow: 0 0 4px #D9D9D9;

  user-select: none;
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  transition: opacity $trans-default, height $trans-default, visibility $trans-default;

  @include vp-767 {
    height: 0;
    overflow: hidden;
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding-top: 0;

    &.is-active {
      height: 100%;
      visibility: visible;
      overflow: visible;
      opacity: 1;
      position: static;
    }
  }
}

.main-nav__subsublist,
.main-nav__sublist {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @include vp-767 {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

// .main-nav__sublist {
//   height: 0;
//   max-height: 0;
//   overflow: hidden;
//   transition:  height $trans-default, max-height $trans-default;

//   @include vp-767 {
//     height: 100%;
//     max-height: 100%;
//   }
// }

.main-nav__subitem {
  position: relative;

  @include vp-767 {
    position: relative;
    display: flex;
    flex-direction: column;

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: $color-light-gray;

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.main-nav__sublist {
  flex-wrap: wrap;
  column-gap: 55px;


  @include vp-1023 {
    column-gap: 70px;
  }
}


.main-nav__sublink {
  @include link-interactive;

  padding-bottom: 60px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;

  font-size: 18px;
  font-weight: 600;
  line-height: 110%;
  width: 340px;

  @include vp-1279 {
    font-size: 14px;
  }

  @include vp-1023 {
    width: 210px;
    padding-bottom: 40px;
  }

  @include vp-767 {
    width: 100%;
    font-size: 12px;
    padding: 20px;

    svg {
      display: none;
    }
  }

  svg {
    transform: rotate(0);
    transition: transform $trans-default;

    @include vp-1023 {
      width: 10px;
      height: auto;
    }
  }
}

.main-nav__subsubmenu {
  position: absolute;
  top: 75%;
  z-index: 2;

  padding: 60px 60px 0;
  width: 100%;

  background-color: #FCFAF8;
  border: 1px solid #EAEAEA;
  box-shadow: 0 0 4px #D9D9D9;

  user-select: none;
  opacity: 0;
  visibility: hidden;
  height: 0;

  transition: opacity $trans-default, height $trans-default;

  @include vp-1023 {
    padding: 40px 40px 0;
  }
}

.main-nav__subsublist {
  flex-direction: column;

  @include vp-767 {
    height: 100%;
  }
}

// Наведение

.main-nav__item {
  @include hover-focus {
    .main-nav__link {
      color: $color-accent-main;
      outline: none;

      svg {
        transform: rotate(180deg);
      }
    }

    .main-nav__submenu {
      user-select: all;
      opacity: 1;
      height: 260px;
      visibility: visible;
      overflow: visible;
    }
  }
}

.main-nav__subitem {
  @include hover-focus {
    .main-nav__sublink {
      color: $color-accent-main;
      outline: none;

      svg {
        transform: rotate(180deg);
      }
    }

    .main-nav__subsubmenu {
      user-select: all;
      opacity: 1;
      visibility: visible;
      height: 220px;

      @include vp-1023 {
        height: 160px;
      }
    }
  }
}
