.products-filter {
  width: 100%;
  display: flex;
  flex-direction: column;
  // padding: 15px 25px;
  gap: 30px;

  @include vp-1023 {
    padding: 0;
  }
}

.products-filter__wrapper {
  transition: height $trans-modal, opacity $trans-modal, visibility $trans-modal;

  form {
    display: flex;
    flex-direction: column;
    gap: 80px;

    @include vp-767 {
      gap: 30px;
    }
  }

  @include vp-767 {
    background-color: $color-bg-layout;
    border-radius: 0;
    padding: 0;
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    height: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 10;
  }

  &.is-active {
    height: 100%;
    opacity: 1;
    visibility: visible;
    overflow-y: scroll;

    form {
      background-color: #fffefc;
      border-radius: 0 0 40px 40px;
      padding: 60px 20px 60px;
      height: calc(100% - 60px);
      overflow-y: scroll;
    }

  }
}


.products-filter__btn-submit {
  display: none;
  justify-self: center;

  @include vp-767 {
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 20px;
  }
}

.products-filter__btn-reset {
  display: flex;
  align-self: center;

  @include vp-767 {
    display: none;
  }
}

.products-filter__inner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20% 10%;
  background-color: #f2f2f2;
  border-radius: 10px;

  @include vp-767 {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
  }
}

.products-filter__toggle {
  position: relative;

  input {
    display: block;
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    clip: rect(0 0 0 0);

    &:checked + .toggle__label {
      color: $color-default-white;
      background-color: $color-accent-main;
      border-color: $color-accent-main;

    }

    @include hover-focus {
      & + .toggle__label {
        border-color: $color-accent-main;
        color: $color-accent-main;
      }
    }

    &:checked {
      @include hover-focus {
        & + .toggle__label {
          color: $color-accent-main;
          background-color: transparent;
        }
      }
    }
  }

  .toggle__label {
    border-radius: 6px;
    padding: 10px 20px;
    border-width: 1px;
    border-style: solid;
    border-color: #F6F6F6;
    position: relative;
    display: flex;
    background-color: $color-default-white;

    font-size: 14px;

    transition: color $trans-default, background-color $trans-default, border-color $trans-default;

    @include vp-1023 {
      font-size: 12px;
      padding: 6px 14px;
    }

    @include vp-767 {
      font-size: 12px;

    }
  }
}
