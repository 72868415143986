.title {
  padding: 0;
  margin: 0;

  font-family: $ff-default;

  &--large {
    color: $color-default-black;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    @include vp-1023 {
      font-size: 30px;
    }
    @include vp-767 {
      font-size: 18px;
      line-height: 130%;
    }
  }

  &--contacts {
    color: $color-default-black;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

    @include vp-1023 {
      font-size: 32px;
    }
    @include vp-767 {
      color: $color-default-white;
      font-size: 28px;
    }
  }

  &--dark {
    color: $color-default-white;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;

    @include vp-1023 {}
    @include vp-767 {
      color: $color-default-black;
    }
  }

  &--medium {
    font-family: $ff-default;
    color: $color-default-black;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include vp-1023 {
      font-size: 30px;
    }

    @include vp-767 {
      font-size: calc(1em + 1.57vw);
      line-height: normal;
    }
  }

  &--small {
    font-size: 25px;
    font-weight: 600;
    line-height: normal;

    @include vp-1023 {
      font-size: 18px;
    }

    @include vp-767 {
      font-size: calc(1em + 1.57vw);
    }
  }

  &--hero2 {
    text-transform: uppercase;
    font-size: 40px;
    line-height: normal;
    font-weight: 700;
    letter-spacing: 5px;

    @include vp-1023 {
      font-size: 30px;
    }

    @include vp-767 {
      font-size: 18px;
      letter-spacing: 0;
    }
  }

  &--service {
    font-size: 50px;
    line-height: normal;
    font-weight: 700;

    @include vp-1023 {
      font-size: 30px;
    }

    @include vp-767 {
      font-size: 18px;
      letter-spacing: 0;
    }
  }

  &--about {
    font-weight: 800;
    font-size: 36px;
    line-height: 150%;
    color: #000000;

    @include vp-1023 {
      font-size: 30px;
    }

    @include vp-767 {
      font-size: 18px;
    }
  }
}
