.php-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  width: 600px;
  height: 300px;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  border-radius: 20px;

  background-color: $color-bg-light;

  margin: 100px auto;

  a {
    text-decoration: none;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    background-color: $color-accent-main;
    font-size: 20px;
    line-height: 100%;
    font-weight: 700;
  }

  span {
    font-weight: 600;
    font-size: 18px;
    color: $color-default-black;
  }
}
