.index-form {
  padding: 80px 0;
  display: flex;

  @include vp-1023 {
    padding: 60px 0;
  }
  @include vp-767 {
    padding: 10px 0;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 516fr 644fr;
    width: 100%;

    @include vp-1023 {
      grid-template-columns: 644fr 516fr;
    }

    @include vp-767 {
      grid-template-columns: 1fr;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background-color: $color-form-layout;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      @include vp-767 {
        display: none;
      }
    }

    @include vp-1023 {}
    @include vp-767 {
      display: none;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__column {
    padding: 70px 10% 90px;
    background-color: $color-bg-dark;
    color: $color-default-white;

    @include vp-1023 {
      padding: 25px 25px 50px;
    }

    @include vp-767 {
      padding: 20px 0;
      background-color: transparent;
      color: $color-default-black;
    }
  }

  &__text {
    padding: 0;
    margin: 0;

    margin-bottom: 40px;

    font-size: 16px;
    line-height: 160%;
    color: $color-text-form;

    @include vp-1023 {
      font-size: 14px;
    }
    @include vp-767 {
      font-size: 12px;
      color: $color-default-black;
    }
  }

  &__title {
    text-align: center;
    padding-bottom: 20px;
    color: $color-default-white;

    @include vp-767 {
      color: $color-default-black;
      text-align: left;
    }
  }
}
