.container {
  --container-offset: 25px;
  width: 100%;
  max-width: calc(1278px + var(--container-offset) * 2);
  margin: 0 auto;
  padding: 0 var(--container-offset);

  @include vp-1023 {
    --container-offset: 40px;
    max-width: unset;
  }

  @include vp-767 {
    --container-offset: 20px;
  }
}
