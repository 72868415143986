.hero2 {
  display: flex;
  flex-direction: column;

  padding: 50px 0 100px;

  @include vp-1023 {
    padding: 30px 0 50px;
  }

  @include vp-767 {
    padding: 20px 0 24px;

    position: relative;

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: -64px;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(255, 236, 218, 0.64) 1.83%, rgba(188, 153, 117, 0.2) 65.37%, rgba(97, 76, 57, 0) 89.62%);
    }


  }
}

.hero2__wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;

  &::after {
    content: "";
    width: 100%;
    height: 100%;

    background: $color-hero2-layout;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    @include vp-767 {
      display: none;
    }
  }
}

.hero2__inner {
  padding: 20% 0 8.2%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 34%;

  @include vp-767 {
    padding: 10% 0 6%;
    width: 100%;
    gap: 20px;
  }
}

.hero2__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;

  img {
    width: 100%;
    height: 100%;
    object-position: top;
    object-fit: cover;
  }

  @include vp-767 {
    display: none;
  }
}

.hero2__text {
  margin: 0;
  padding: 0;

  font-size: 20px;
  line-height: 140%;

  @include vp-1023 {
    font-size: 14px;
  }

  @include vp-767 {
    font-size: 12px;
  }
}
