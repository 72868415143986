.about-partners {
  width: 100%;
  padding-bottom: 68px;
}

.about-partners__text {
  margin: 0;
  padding: 30px 0 36px;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #000000;

  width: 80%;

  @include vp-1023 {
    font-size: 16px;
  }

  @include vp-767 {
    font-size: 12px;
    width: 90%;
    padding: 20px 0 10px;
  }
}


.about-partners__swiper {
  padding-bottom: 100px;

  .about-partners__pagination {
    top: auto;
    bottom: 40px;
    width: 80%;
    // background: rgba($color-accent-main, 0.33);

    span {
      border: none;
    }

    &.swiper-pagination-progressbar > .swiper-pagination-progressbar-fill {
      background: $color-accent-main;
    }
  }
}

.about-partners__wrapper {
  height: auto;
}

.about-partners__btn {
  bottom: 24px;

  &--prev {
    left: auto;
    right: 10%;
  }

  &--next {
    left: auto;
    right: 0;
  }

  @include vp-767 {
    bottom: 30px;
  }
}

.about-partners__slide {
  width: 234px;
  height: 264px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  @include vp-1023 {
    width: 160px;
    height: 180px;
  }

  @include vp-767 {
    width: calc(100% - 20px);
    height: auto;
  }
}
