.custom-input,
.custom-textarea {
  position: relative;

  display: flex;
  width: 530px;
  padding: 14px 0 28px 0;

  @media screen and (max-width: 767px) {
    padding: 4px 0 48px 0;
    width: 100%;
  }

  label {
    display: flex;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 767px) {
      align-items: flex-start;
      flex-direction: column;
    }

    input,
    textarea {
      flex-grow: 1;
      max-width: 300px;
      min-height: 48px;
      margin-left: auto;
      padding: 10px;

      border: 2px solid rgba(#000000, 0.2);
      border-radius: 5px;
      outline: none;

      transition: border 0.3s ease;

      @media screen and (max-width: 767px) {
        max-width: unset;
        width: 100%;
      }

      &:hover {
        border: 2px solid rgba(#000000, 0.5);
      }

      &:focus {
        border: 2px solid rgba(#000000, 0.8);
      }
    }
  }

  &__label {
    @media screen and (max-width: 767px) {
      margin: 0 0 18px 0;
    }
  }

  &__error {
    position: absolute;
    right: 0;
    bottom: 35px;

    color: #ff1553;

    opacity: 0;

    transition: opacity 0.3s ease;

    @media screen and (max-width: 767px) {
      bottom: 20px;
    }
  }

  &.is-invalid {
    input,
    textarea {
      border: 2px solid #ff1553;

      &:focus,
      &:hover {
        border: 2px solid rgba(#ff1553, 0.5);
      }
    }
  }

  &.is-valid {
    input {
      border: 2px solid #22b259;

      &:focus,
      &:hover {
        border: 2px solid rgba(#22b259, 0.5);
      }
    }
  }

  .input-message {
    position: absolute;
    right: 0;
    bottom: -5px;

    &.is-invalid {
      color: #ff0000;
    }

    &.is-valid {
      color: #15ab07;
    }
  }
}
