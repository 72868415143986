.form {
  display: flex;
  padding-bottom: 80px;
  display: flex;
  position: relative;

  @include vp-1023 {
    padding-bottom: 60px;
  }
  @include vp-767 {
    padding-bottom: 40px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;


    @include vp-767 {
      color: $color-default-black;
    }
  }
}

.form__btn {
  letter-spacing: 2px;
  align-self: center;
  background-color: transparent;
  border: none;
  color: $color-default-white;
  position: relative;
  z-index: 2;
  cursor: pointer;

  @include vp-767 {
    color: $color-default-black;
  }
}

.form__success,
.form__error {
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
  color: $color-accent-main;
  position: absolute;
  bottom: 0;

  opacity: 0;
  visibility: hidden;

  transition: opacity $trans-default;

  @include vp-767 {
    font-size: 18px;
  }

  &.is-open {
    opacity: 1;
    visibility: visible;
  }
}
