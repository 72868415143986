.form-input,
.form-textarea {
  width: 100%;

  label {
    display: flex;
    flex-direction: column;
  }

  &.is-invalid {
    input,
    textarea {
      border: 1px solid $color-accent-hover;

      &:focus,
      &:hover {
        border: 1px solid rgba($color-accent-hover, 0.5);
      }
    }
  }

  input,
  textarea   {
    flex-grow: 1;
    color: $color-text-form;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba($color-text-form, 0.3);
    background-color: transparent;
    transition: border-color $trans-default;

    &:hover,
    &:focus {
      outline: none;
      border-color: $color-text-form;
    }

    @include vp-767 {
      color: $color-default-black;
      border-color: rgba($color-default-black, 0.3);

      &:hover,
      &:focus {
        outline: none;
        border-color: $color-default-black;
      }
    }
  }

  input {
    min-height: 50px;
  }

  textarea {
    min-height: 100px;
  }


}

.form-input__label,
.form-textarea__label {
  color: $color-text-form;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;

  @include vp-767 {
    color: $color-default-black;
  }
}

.form-input--modal,
.form-textarea--modal {

  label {
    display: flex;
    flex-direction: column;
  }

  input,
  textarea   {
    color: $color-bg-dark;
    border-color: rgba($color-bg-dark, 0.3);

    &:hover,
    &:focus {
      outline: none;
      border-color: $color-bg-dark;
    }
  }

  .form-input__label,
  .form-textarea__label {
    color: $color-bg-dark;
  }
}
