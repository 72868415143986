.service-main {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  font-size: 18px;
  line-height: 150%;

  p,
  ul,
  li {
    text-align: justify;
    margin: 0;
  }

  @include vp-1023 {
    padding-bottom: 50px;
  }

  @include vp-767 {
    font-size: 14px;
  }
}

.service-main__top {
  display: inline;

  p {
    display: inline;
    margin: 0;
  }
}

.service-main__title {
  font-size: 20px;
  font-weight: 600;

  @include vp-767 {
    font-size: 16px;
  }
}

.service-main__middle {
  margin-top: 30px;

  @include vp-767 {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 30px;
  }
}

.service-main__modal-link {
  // padding: 20px;
  display: flex;
  width: 435px;
  float: right;
  margin: 10px 0 10px 30px;
  padding-top: 0;

  @include vp-1023 {
    width: 365px;
    & > .modal-link__icon {
      padding: 15px;
    }
  }

  @include vp-767 {
    float: none;
    order: 2;
    width: 100%;
    margin: 0 auto;
  }

  .modal-link__button::after {
    width: 32%;

    @include vp-1023 {
      width: 22%;
    }

    @include vp-767 {
      width: 60px;
    }
  }

  .modal-link__text {
    display: none;
  }

  .modal-link__short-text {
    display: flex;
  }

  .modal-link__wrapper {
    display: flex;
  }
}

.service-main__bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 0;

  @include vp-767 {
    padding: 20px 0;
    flex-direction: column;
    gap: 15px;
  }
}

.service-main__link {
  width: 40%;
  display: flex;
  align-items: flex-end;
  transition: color $trans-default;
  font-size: 18px;
  padding: 0;

  @include vp-767 {
    width: 100%;
    font-size: 14px;
  }

  @include hover-focus {
    color: $color-accent-hover;
  }
}
