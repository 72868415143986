.services-catalog {
  padding-bottom: 80px;

  @include vp-1023 {
    padding-bottom: 35px;
  }

  @include vp-767 {
    padding-bottom: 30px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__catalog {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    margin-bottom: 45px;

    @include vp-1023 {
      gap: 30px;
      margin-bottom: 34px;
    }

    @include vp-767 {
      grid-template-columns: 1fr;
      gap: 24px;
      margin-bottom: 30px;
    }
  }

  &__button {
    justify-self: center;

    &.is-hidden {
      display: none;
    }
  }

}
