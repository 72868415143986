.hero-slide {
  padding: 16% 0 13%;
  background: transparent;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;

  position: relative;

  @include vp-1279 {
    padding: 18% 0 13%;
  }

  @include vp-767 {
    padding: 20% 0 18%;
    background: $color-hero-layout-mobile;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    padding: 68px 1% 70px calc(50% - 639px);

    width: 100%;

    @include vp-1327 {
      padding-left: 25px;
    }

    @include vp-1279 {
      padding: 48px 6% 40px 25px;
    }

    @include vp-767 {
      padding: 24px 6% 26px 20px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(20px);
      border-radius: 0;

      z-index: -2;

      transition: width $trans-default, border-radius $trans-default;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $color-bg-light;
      border-radius: 0;

      z-index: -1;

      transition: width $trans-default, border-radius $trans-default;
    }
  }

  &.swiper-slide-active > .hero-slide__wrapper::after,
  &.swiper-slide-active > .hero-slide__wrapper::before {
    border-radius: 0 60px 60px 0;
    width: 70%;

    @include vp-1023 {
      border-radius: 0 50px 50px 0;
      width: 78%;
    }
    @include vp-767 {
      border-radius: 0 40px 40px 0;
      width: 82%;

    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 64%;

    @include vp-1023 {
      width: 75%;
    }
    @include vp-767 {
      font-size: 14px;
      line-height: 130%;
    }
  }

  &__text {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    max-width: 70%;

    @include vp-1279 {
      font-size: 18px;
      min-height: 90px;
    }

    @include vp-1023 {
      font-size: 16px;
    }
    @include vp-767 {
      max-width: 100%;
      min-height: 98px;
      font-size: 12px;
      line-height: 130%;
    }
  }

  &__btn {
    align-self: flex-end;
  }

  &__title {
    margin-bottom: 20px;

    font-size: 50px;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -1px;

    @include vp-1279 {
      font-size: 46px;
    }

    @include vp-1023 {
      min-height: 71px;
      font-size: 30px;
    }
    @include vp-767 {
      min-height: 71px;
      font-size: 18px;
      line-height: 130%;
      letter-spacing: 0;
    }
  }
}

.hero-slide__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -3;
  // opacity: 0.96;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
  }

  @include vp-767 {
    display: none;
  }

  &::after {
    opacity: 0.9;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $color-hero-layout;
  }
}
