.btn {
  font-family: $ff-default;
  display: inline-flex;
  align-items: center;
  padding: 10px 30px;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;

  &--arrow {
    gap: 12px;
    background-color: $color-accent-main;
    letter-spacing: 1px;

    border-radius: 5px;

    color: $color-default-white;
    transition: background-color $trans-default, color $trans-default, opacity $trans-default;

    svg {
      transition: transform $trans-default;

      @include vp-1023 {
        width: 7px;
        height: auto;
      }

      @include vp-767 {
        width: 6px;
        height: auto;
      }
    }

    @include vp-1279 {
      font-size: 16px;
    }

    @include vp-1023 {
      border-radius: 2px;
      padding: 6px 20px;
      font-size: 14px;
    }

    @include vp-767 {
      padding: 4px 14px;
      font-size: 10px;
    }

    @include hover-focus {
      box-shadow: 0 0 6px $color-bg-light;

      svg {
        transform: translate(8px);
      }

      &:active {
        svg {
          transform: translate(-10px);
        }
      }
    }

    &:active {
      svg {
        transform: translate(-10px);
      }
    }

    &:disabled {
      opacity: 0.2;
    }
  }

  &--line {
    letter-spacing: -1px;
    color: $color-default-black;
    position: relative;

    border: none;
    background-color: transparent;

    span {
      position: relative;
      z-index: 2;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
      height: 10%;
      z-index: -1;
      background-color: rgba($color-accent-main, 0.6);
      transition: height $trans-default;
      border-radius: 2px;
    }

    @include hover-focus {
      &::after {
        height: 100%;
      }
    }

    &:active {
      &::after {
        opacity: 0.5;
      }
    }

    &:disabled {
      opacity: 0.2;
    }

    @include vp-1279 {
      font-size: 16px;
    }

    @include vp-1023 {
      padding: 6px 20px;
      font-size: 14px;
    }

    @include vp-767 {
      padding: 4px 14px;
      font-size: 12px;

    }
  }

  &--news {
    @include link-interactive;

    padding: 0;

    font-family: $ff-default;
    color: $color-default-black;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  &--dark {
    background-color: $color-bg-dark;
    color: $color-default-white;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 18px;
    transition: background-color $trans-default;

    @include vp-1023 {
      font-size: 12px;
      padding: 4px 20px;
    }

    @include vp-767 {
      font-size: 10px;
      padding: 4px 8px;
    }

    @include hover-focus {
      background-color: $color-accent-main;
      outline: none;
      border-color: transparent;
    }

    &:active {
      background-color: $color-accent-main;
      outline: none;
      border-color: transparent;
    }
  }
}
