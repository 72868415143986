.input-upload {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.input-upload input {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.input-upload__preview-item {
  position: relative;
}

.input-upload__preview {
  padding: 10px;
  border-radius: 10px;
}

.input-upload--images-drop .input-upload__preview,
.input-upload--file-drop .input-upload__preview {
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  pointer-events: none;
}

.input-upload--images .input-upload__preview {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 200px);
}

.input-upload--file .input-upload__preview {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
}

.input-upload--images .input-upload__preview-item {
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  width: 200px;
  height: 150px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.input-upload--images .input-upload__preview-icon {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: auto;
  left: 10px;
  top: 10px;
}

.input-upload--images .input-upload__preview-file-info {
  position: absolute;
  width: 100%;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  padding: 5px 10px;
  z-index: 1;
  left: 0;
  bottom: 0;
}

.input-upload--file .input-upload__preview-item {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 5px 40px 5px 50px;
  margin-top: 10px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.input-upload__preview-file-size {
  white-space: nowrap;
}

.input-upload__preview-file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-upload--file .input-upload__preview-icon {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: auto;
  left: 10px;
}

.input-upload__preview-file-info {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  padding: 10px;
}

.input-upload__preview-img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
}

.input-upload__preview-item-remove {
  border: none;
  padding: 0;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: #2c39f2;
  color: #ffffff;
}

.input-upload--file .input-upload__preview-item-remove {
  top: unset;
}

.input-upload__drop-zone {
  position: relative;
  width: 400px;
  min-height: 400px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  padding: 20px;
  overflow: hidden;
  display: flex;
  cursor: pointer;
}

.input-upload__drop-zone::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.is-drag.input-upload__drop-zone::before {
  opacity: 1;
}

.input-upload__drop-zone-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.not-empty .input-upload__drop-zone-text {
  opacity: 0;
  visibility: hidden;
}

.input-upload--images-drop .input-upload__preview-item {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 150px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  pointer-events: all;
  cursor: auto;
}

.input-upload--images-drop .input-upload__preview-icon {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: auto;
  left: 10px;
  top: 10px;
}

.input-upload--images-drop .input-upload__preview-file-info {
  position: absolute;
  width: 100%;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  padding: 5px 10px;
  z-index: 1;
  left: 0;
  bottom: 0;
}

.input-upload--file-drop .input-upload__preview-item {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 150px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  pointer-events: all;
  cursor: auto;
}

.input-upload--file-drop .input-upload__preview-icon {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: auto;
  left: 10px;
  top: 10px;
}

.input-upload--file-drop .input-upload__preview-file-info {
  position: absolute;
  width: 100%;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  padding: 5px 10px;
  z-index: 1;
  left: 0;
  bottom: 0;
}

.input-upload__preview-item.is-invalid {
  border: 1px solid rgba(255, 0, 0, 0.6);
}

.input-upload__preview-item.is-invalid .input-upload__preview-file-size {
  color: #ff0000;
}

.input-upload__preview.is-invalid {
  border: 1px solid rgba(255, 0, 0, 0.6);
}

.input-upload__message {
  position: absolute;
  right: 0;
  bottom: -5px;
  color: #ff0000;
}
