.breadcrumbs {
  padding-bottom: 20px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  @include vp-1023 {
    padding-bottom: 30px;
    gap: 10px;
  }

  @include vp-767 {
    display: none;
  }

  a {
    @include link-interactive;

    padding: 5px 0;
    line-height: 100%;
    color: $color-default-black;

    @include vp-1279 {
      font-size: 14px;
    }

    @include vp-1023 {
      padding: 3px 0;
      font-size: 13px;
    }
  }
}


