.about-licenses {
  width: 100%;
  padding-bottom: 68px;
  padding-top: 20px;
}

.about-licenses__text {
  margin: 0;
  padding: 30px 0 36px;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #000000;

  width: 80%;

  @include vp-1023 {
    font-size: 16px;
  }

  @include vp-767 {
    font-size: 12px;
    // text-align: center;
    // width: 100%;
  }
}


.about-licenses__swiper {
  padding-bottom: 100px;

  .about-licenses__pagination {
    top: auto;
    bottom: 40px;
    width: 80%;
    // background: rgba($color-accent-main, 0.33);

    span {
      border: none;
    }

    &.swiper-pagination-progressbar > .swiper-pagination-progressbar-fill {
      background: $color-accent-main;
    }
  }
}

.about-licenses__wrapper {
  height: auto;
}

.about-licenses__btn {
  bottom: 24px;

  &--prev {
    left: auto;
    right: 10%;
  }

  &--next {
    left: auto;
    right: 0;
  }

  @include vp-767 {
    bottom: 30px;
  }
}

.about-licenses__slide {
  width: 234px;
  height: 334px;
  box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;

  display: flex;

  @include vp-1023 {
    width: 196px;
    height: 280px;
  }

  @include vp-767 {
    width: calc(100% - 20px);
    height: auto;
  }
}
