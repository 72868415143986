.index-about {
  padding: 80px 0;
  width: 100%;

  @include vp-1023 {
    padding:40px 0;
  }

  @include vp-767 {
    padding: 30px 0;
  }
}

.index-about__wrapper {
  display: grid;
  grid-template-columns: 1fr 50%;
  grid-template-areas:
    "text image"
    "btn image";
  align-items: center;
  gap: 3.5%;

  @include vp-1023 {}

  @include vp-767 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.index-about__left {
  grid-area: text;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 32px;

  p {
    margin: 0;
    padding: 0;
    line-height: 140%;
    text-align: justify;

    @include vp-1023 {
      font-size: 14px;
      width: 80%;
    }

    @include vp-767 {
      font-size: 12px;
      width: 100%;
    }
  }
}


.index-about__image {
  grid-area: image;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 0 82%;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
  }

  @include vp-767 {
    padding: 0 0 143%;
  }
}

.index-about__btn {
  display: flex;
  grid-area: btn;
  justify-self: end;

  @include vp-767 {
    align-self: center;
  }
}
