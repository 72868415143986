.about-acc {
  width: 100%;
  padding-bottom: 60px;
}

.about-acc__list {
  padding: 30px 0;
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;

  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #000;

  @include vp-1023 {
    font-size: 14px;
    padding: 20px 0;
    gap: 6px;
  }


}

.about-acc__item {
  display: grid;
  grid-template-columns: 322fr 700fr;
  background-color: transparent;
  padding: 15px 10px;

  &:nth-child(odd) {
    background-color: #F2F2F2;
  }

  @include vp-1023 {
    padding: 10px 5;
  }

  @include vp-767 {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.about-acc__key {
  font-weight: 600;
}
