.events {
  width: 100%;
  display: flex;
  padding: 0 0 30px;
}

.events__title {
  width: 70%;
  padding-bottom: 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;

  @include vp-767 {
    font-size: 16px;
    width: 100%;
  }
}
