.search {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include vp-1023 {
    max-width: 100%;
  }

  @include vp-767 {
    padding: 0;
    font-size: 14px;
  }

  form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    height: 100%;
    width: 100%;


    position: relative;
    padding: 10px 40px 10px 20px;
    display: flex;
    align-items: center;
    background: #F2F2F2;
    border: 1px solid #F3F3F3;
    border-radius: 10px;
    transition: opacity $trans-default;

    @include vp-767 {
      padding: 5px 30px 5px 3px;
    }

    label {
      display: flex;
      gap: 3px;
      align-items: center;
      width: 100%;
      padding-right: 32px;
    }

    input {
      border: none;
      background-color: transparent;
      color: $color-default-black;
      padding: 5px;
      flex-grow: 1;

      @include hover-focus {
        outline: none;
      }
    }

    svg {
      @include vp-767 {
        width: 12px;
        height: 12px;
      }
    }
  }

  .search__reset {
    border: none;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translate(0, -50%);
    padding: 16px;
    background: transparent;
    opacity: 0;
    transition: opacity $trans-default;
    visibility: hidden;

    &::after,
    &::before {
      position: absolute;
      content: "";
      height: 1px;
      width: 16px;
      background-color: $color-default-black;
      left: 28%;

      transition: background-color $trans-default;
    }

    &::after {
      transform: rotate(-45deg);
    }

    &::before {
      transform: rotate(45deg);
    }

    &:hover {
      &::after,
      &::before {
        background-color: $color-accent-hover;
      }
    }

    &.is-active {
      opacity: 1;
      visibility: visible;
    }
  }

  &:hover,
  &[data-opacity ="1"] {
    .search__reset {
      opacity: 1;
    }
  }
}

.search__submit {
  cursor: pointer;
  user-select: none;

  position: absolute;
  right: 14px;
  top: 50%;
  transform: translate(0, -50%);
  padding: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  color: #AFAFAF;
  padding: 4px;

  transition: color $trans-default, opacity $trans-default;

  svg {
    width: 16px;
    height: 16px;
  }

  @include hover-focus {
    color: $color-accent-hover;
    outline: none;
  }

  &:active {
    color: $color-accent-hover;
  }

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.search__error {
  color: $color-accent-main;
  padding: 0 15px;
  visibility: hidden;
  opacity: 0;

  transition: opacity $trans-default;

  @include vp-767 {
    padding: 0 8px;
  }

  &.is-active {
    opacity: 1;
    visibility: visible;
  }
}
