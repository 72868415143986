.advantages {
  width: 100%;

  @include vp-1023 {}

  @include vp-767 {
    padding: 0 20px;
    margin: 0 auto;
  }

  &__swiper {
    padding-top: 100px;
    padding-bottom: 120px;

    @include vp-1023 {
      padding-bottom: 100px;
    }
    @include vp-767 {
      padding-top: 60px;
      padding-bottom: 80px;
    }
  }

  &__swiper-button {
    width: 36px;
    height: 36px;
    top: auto;
    bottom: 20px;

    &::after {
      font-size: 18px;
    }

    &--next {
      left: calc(50% + 21px);
    }

    &--prev {
      left: calc(50% - 39px);
    }

    @include vp-767 {
      width: 24px;
      height: 24px;

      &::after {
        font-size: 14px;
      }

      &--next {
        left: calc(50% + 15px);
      }

      &--prev {
        left: calc(50% - 27px);
      }
    }
  }

}


