.form-toggle {
  width: 100%;
  cursor: pointer;

  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
  }

  &.is-invalid {
    .toggle__icon {
      border-color: $color-accent-hover;
    }
  }


  input {
    display: block;
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    clip: rect(0 0 0 0);

    &:checked + .toggle__icon {
      color: $color-default-black;

      svg {
        display: block;
      }
    }

    @include hover-focus {
      & + .form-toggle__icon {
        border-color: $color-accent-main;
      }
    }

    &:checked {
      @include hover-focus {
        & + .toggle__icon {
          // border-color: transparent;
          color: $color-accent-main;
        }
      }
    }
  }

  .toggle__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: $color-default-white;
    cursor: pointer;
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;

    svg {
      display: none;
    }

    @include vp-767 {
      width: 15px;
      height: 15px;
      background-color: $color-default-white;
      border-color: $color-default-black;
    }
  }

  .toggle__label {
    font-size: 14px;
    line-height: 110%;
    cursor: pointer;

    @include vp-1023 {
      font-size: 12px;
    }

    @include vp-767 {
      color: $color-default-black;
    }
  }
}
